<template>
  <div class="auth-wrapper auth-v2">
  </div>
</template>

<script>
/* eslint-disable global-require */
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

  data() {
    return {
      key: ''
    }
  },
  async mounted() {
    this.key = this.$route.params.key;
    if (this.key) {
      useJwt
        .verify_account(this.key, {})
        .then(() => {

          this.$router
            .replace('/login')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Welcome ${userData.first_name} ${userData.last_name}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully verified your account. Now you can login & explore!`,
                },
              });
            });
        }).catch((error) => {
          this.$router
            .replace('/login')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't verify this account.`,
                },
              });
            });
        });
    } else {
      this.$router
        .replace('/login')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't verify this account.`,
            },
          });
        });
    }
  },
}
/* eslint-disable global-require */
</script>
